<template>
    <div class="basicSet">
        <a-modal centered :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
            <img alt="example" style="width: 100%" :src="previewImage" />
        </a-modal>
        <a-modal centered title="保存题库详情模板" :visible="modelVisible" @cancel="modelVisible = false; modelName = null" @ok="submitModel">
            <a-row type="flex" justify="center" align="middle">
                <a-col :span="4">
                    模板名：
                </a-col>
                <a-col :span="18">
                    <a-input v-model="modelName" />
                </a-col>
            </a-row>
           
        </a-modal>
        <a-form :hideRequiredMark="true" v-bind="formItemLayout" :form="form" @submit="handleSubmit" v-if="id">
            <a-form-item label="题库主图" >
                <a-upload
                    name=""
                    list-type="picture-card"
                    :show-upload-list="false"
                    action=""
                    :customRequest="customRequest"
                >
                <img v-if="filter.main_pic" :src="setUrl(filter.main_pic)" alt="" />
                <div v-else>
                    <a-icon :type="loading ? 'loading' : 'plus'" style="font-size:30px;color:#dcdcdc"/>
                </div>
            </a-upload>
            </a-form-item>
            <a-form-item label="题库详情页" >
                <div class="mb10x csP_E" v-if="modelList.length">常用模板：
                    <a-tag color="cyan" closable @close="(e)=>{e.preventDefault();removeModel(item.id)}" v-for="item in modelList" :key="item.id" @click="checkModel(item)">{{item.name}}</a-tag>
                </div>
                <div class="img-wrap clearfix">
                    <draggable v-model="filter.detail_pic" v-if="filter.detail_pic.length">
                        <div class="img-box imgGroup" v-for="(item, index) in filter.detail_pic" :key="index">
                            <div class="num">{{index+1}}</div>
                            <div class="delIcon" @click="remove(index)">
                                <a-icon type="close-circle"  />
                            </div>
                            <div class="imgWrap" @click="movePic(item)">
                                <img :src="setUrl(item)" alt="avatar" />
                            </div>
                        </div>
                    </draggable>
                    <div class="img-box">
                        <a-upload
                            name="avatar"
                            list-type="picture-card"
                            :show-upload-list="false"
                            action=""
                            :customRequest="customRequestDetail"
                        >
                            <div>
                                <a-icon :type="detailLoading ? 'loading' : 'plus'" style="font-size:30px;color:#dcdcdc"/>
                            </div>
                        </a-upload>
                    </div>
                </div>
                <div v-if="filter.detail_pic.length">
                    <a-button type="primary" @click="modelVisible = true; modelName = null">保存为模板</a-button>
                </div>
            </a-form-item>
            <a-form-item label="题库原价" >
                 <a-input-number :precision="2" class="w400" 
                    v-decorator="['cost_price', 
                    { 
                        initialValue: filter.cost_price,  
                        rules: [ { required: true, message: '请输入教材原价!' },
                    ]}]"  placeholder="请输入教材原价" />
            </a-form-item>
            <a-form-item label="题库现价" >
               <a-input-number :precision="2" class="w400" v-decorator="['now_price', 
                    { 
                        initialValue: filter.now_price,  
                        rules: [ { required: true, message: '请输入教材现价!' },
                    ]}]"  placeholder="请输入教材现价" />
            </a-form-item>
            <a-form-item label="系统价格" >
               <a-input-number :precision="2" class="w400" v-decorator="['sys_price', 
                    { 
                        initialValue: filter.sys_price,
                        rules: [ { required: true, message: '请输入系统价格!' },
                    ]}]"  placeholder="请输入系统价格" />
            </a-form-item>
            <a-form-item label="购买基数">
               <a-input type="number" class="w400"
                v-decorator="['buy_num', 
                    { 
                        initialValue: filter.buy_num,  
                        rules: [ { required: true, message: '请输入购买基数!' },
                    ]}]"  placeholder="请输入购买基数" />
            </a-form-item>
            <a-form-item :wrapper-col="{ offset: 4 }">
              <a-button type="primary" class="w400" html-type="submit">提交</a-button>
            </a-form-item>
        </a-form>
        <div v-else class="nodata">
           <a-empty :description="'请先选择科目'"/>
        </div>
    </div>
</template>
<script>
import * as http from '@/libs/examapi'
import { uploadImg } from '@/libs/api'
export default {
    name: 'questionSet',
    props:["id"],
    data(){
        return{
            form: this.$form.createForm(this),
            loading: false,
            detailLoading: false,
            waitTime: 0,
            formItemLayout:{
                labelCol: { span: 4 },
                wrapperCol: { span:18 },
            },
            filter:{
                main_pic:'',
                detail_pic: [],
                cost_price: '',
                now_price: '',
                buy_num: '',
                sys_price: ''
            },
            //图片预览
            previewVisible: false,
            previewImage: null,
            //muban
            modelVisible: false,
            modelName: null,
            modelList: []
        }
    },
    watch:{
        id(val){
            if(val){
                this.getDetail()
            }
        }
    },
    mounted(){
        this.getDetail()
        this.getModelList()
    },
    methods:{
        reset(){
            this.filter={
                main_pic:'',
                detail_pic:[],
                cost_price: '',
                now_price: '',
                buy_num: '',
            }
        },
        setUrl(url){
            return url.split(':')[1]
        },
        getDetail(){
            this.form.resetFields();
            this.reset()
            http.getQuestionSet({cate_id:this.id}).then(res => {
                if(res){
                    this.filter = res
                    this.filter.main_pic = res.main_pic
                    this.filter.detail_pic = res.detail_pic.split(',')
                }
            })
        },
      
        customRequest(data){
            let formData = new FormData()
            formData.append('file',data.file)
            this.loading = true
            uploadImg(formData).then(res=>{
                this.loading = false
                this.filter.main_pic=res.fileUrl
            },error=>{
                console.log(error)
                this.loading = false
            })
        },
        customRequestDetail(data){
            let formData = new FormData()
            formData.append('file',data.file)
            this.detailLoading = true
            uploadImg(formData).then(res=>{
                this.detailLoading = false
                this.filter.detail_pic.push(res.fileUrl)
            },error=>{
                console.log(error)
                this.detailLoading = false
            })
        },
        handleSubmit(e){
            e.preventDefault();
            this.form.validateFields((err, values) => {
                if (!err) {
                    if(!this.filter.main_pic){
                        this.$message.error('请上传题库主图')
                        return false
                    }
                    if(!this.filter.detail_pic){
                        this.$message.error('请上传题库详情页')
                        return false
                    }
                    
                    let request = {
                        ...this.filter,
                        ...values,
                        cate_id: this.id
                    }
                    http.addQuestionSet(request).then(res => {
                        this.$message.success('提交成功')
                    }).catch(error => {
                        console.log(error)
                    })
                }
            });
        },
        remove(index){
            this.filter.detail_pic.splice(index, 1)
        },
        movePic(pic){
            this.previewImage = pic;
            this.previewVisible = true;
        },
        getModelList(){
            http.getDetailModel().then(res =>{
               this.modelList = res
            }).catch(error => {
                console.log(error)
            })
        },
        submitModel(){
            if(!this.modelName){
                return
            }
            http.addDetailModel({name: this.modelName, detail_pic: this.filter.detail_pic}).then(res => {
                this.modelVisible = false
                this.getModelList()
            }).catch(error => {
                console.log(error)
            })
        },
        checkModel(data){
            let { detail_pic } = data
            this.filter.detail_pic = detail_pic.split(',')
        },
        removeModel(id){
            let _this = this
            this.$confirm({
                title: '是否确定删除该条模板?',
                centered: true,
                onOk() {
                    http.delDetailModel({id}).then(res => {
                       _this.getModelList()
                    })
                },
            });
        }
    }
}
</script>
<style scoped>
.nodata{
    text-align: center;
}
.basicSet{
    background: #fff;
    padding: 40px;
}
.w400{
    width: 400px
}
.img-box{
    display: table;
    float: left;
}
.imgGroup{
    width: 104px;
    height: 104px;
    margin-right: 15px;
    margin-bottom: 8px;
    text-align: center;
    vertical-align: top;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 4px;
    cursor: pointer;
    -webkit-transition: border-color 0.3s ease;
    transition: border-color 0.3s ease; 
    position: relative
}
.num{
    position: absolute;
    top: -8px;
    left: 44px;
    width: 16px;
    height: 16px;
    display: flex;
    line-height: 1;
    border-radius: 100%;
    justify-content: center;
}
.imgWrap{
    display: table-cell;
    width: 100%;
    height: 100%;
    padding: 8px;
    text-align: center;
    vertical-align: middle;
}
.delIcon{
    position: absolute;
    width: 16px;
    height: 16px;
    top: -8px;
    right: -8px;
    display: flex;
    font-size: 16px;
    background: #fff;
    color: #ff5f4e;
}
</style>